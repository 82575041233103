import * as THREE from 'three';
import ammo from './ammo.js';

export function btInspectQuat(quaternion) {
  return `{x: ${quaternion.x()}, y: ${quaternion.y()}, z: ${quaternion.z()}, w: ${quaternion.w()}}`;
}

export function btInspectVec3(vector) {
  return `{x: ${vector.x()}, y: ${vector.y()}, z: ${vector.z()}}`;
}

export function bt2ThreeVec3(vector) {
  return new THREE.Vector3(vector.x(), vector.y(), vector.z());
}

export function bt2ThreeQuat(quaternion) {
  return new THREE.Quaternion(quaternion.x(), quaternion.y(), quaternion.z(), quaternion.w());
}

export function three2BtQuat(quaternion) {
  return new ammo.btQuaternion(quaternion.x, quaternion.y, quaternion.z, quaternion.w);
}

export function three2BtVec3(vector) {
  return new ammo.btVector3(vector.x, vector.y, vector.z);
}
