import Entity from "../entity.js";
import ammo from "../ammo.js";
import { bt2ThreeQuat, bt2ThreeVec3, three2BtQuat } from "../util.js";
import _ from "lodash";
import * as THREE from "three";
import { Quaternion } from "three";
import { gravity, BT_FLAGS } from "../physics.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

export default class PlanetEntity extends Entity {
  constructor(name, initProps, character) {
    super(name, initProps);

    if (_.isUndefined(initProps.radius) || _.isUndefined(character)) {
      this._throwMissingInitialPropsError();
    }

    this._characterEntity = character;
    this._btCollisionFlag = BT_FLAGS.CF_STATIC_OBJECT;
    this._shape = new ammo.btSphereShape(initProps.radius);
    this._shape.setMargin(0.05);
    this._sphere = {
      visible: true,
    };
    // for the ring
    this._rotation = 0;
    this._ring = null;
  }

  _loadMaterial() {
    let material = new THREE.MeshStandardMaterial();
    const loader = new THREE.TextureLoader().setPath("assets/textures/floor/");

    material.map = loader.load("hardwood-brown-planks-albedo.png");
    material.map.encoding = THREE.sRGBEncoding;

    material.metalnessMap = loader.load("hardwood-brown-planks-metallic.png");
    material.roughnessMap = loader.load("hardwood-brown-planks-roughness.png");
    material.normalMap = loader.load("hardwood-brown-planks-normal-ogl.png");

    const tiling = new THREE.Vector2(20, 20);
    ["map", "roughnessMap", "metalnessMap", "normalMap"].forEach((mapName) => {
      material[mapName].wrapS = material[mapName].wrapT = THREE.RepeatWrapping;
      material[mapName].repeat = tiling;
    });

    return material;
  }

  async _initGraphic() {
    const { radius, position } = this._initProps;

    const geometry = new THREE.SphereGeometry(radius, 32, 32);
    const material = this._loadMaterial();
    const sphere = new THREE.Mesh(geometry, material);
    sphere.position.set(position.x, position.y, position.z);

    let url = "/assets/models/ring4.glb";
    this._ring = await this.loadRing(url, sphere);
    sphere.receiveShadow = true;
    this.graphic = sphere;
  }

  async modelLoader(url, loader) {
    return new Promise((resolve, reject) => {
      loader.load(url, (data) => resolve(data), null, reject);
    });
  }

  async loadRing(url, sphere) {
    const loader = new GLTFLoader();
    const gltfData = await this.modelLoader(url, loader);
    let obj;
    obj = gltfData.scene.children[0];
    obj.scale.set(10, 6, 10);
    obj.castShadow = true;
    sphere.add(obj);

    return obj;
  }

  _applyAttraction() {
    let sphereOrigin = this.body.getWorldTransform().getOrigin();
    let characterOrigin = this._characterEntity.body
      .getWorldTransform()
      .getOrigin();

    // compute and apply sphere gravity to character body
    let sphereAttractionForce = new ammo.btVector3(
      characterOrigin.x() - sphereOrigin.x(),
      characterOrigin.y() - sphereOrigin.y(),
      characterOrigin.z() - sphereOrigin.z()
    );
    sphereAttractionForce.normalize();
    sphereAttractionForce.op_mul(gravity);
    this._characterEntity.body.applyForce(
      sphereAttractionForce,
      ammo.btVector3(0, 0, 0)
    );

    // align character up with sphere origin
    const gravityDirection = bt2ThreeVec3(sphereAttractionForce)
      .normalize()
      .multiplyScalar(-1);

    // extract up axis of character transform basis
    let characterUp = new THREE.Vector3();
    this._characterEntity.graphic.matrixWorld.extractBasis(
      new THREE.Vector3(),
      characterUp,
      new THREE.Vector3()
    );

    // apply rotation to align up with gravity vector
    let verticalAlignmentRotation = new Quaternion()
      .setFromUnitVectors(characterUp, gravityDirection)
      .multiply(
        bt2ThreeQuat(
          this._characterEntity.body.getWorldTransform().getRotation()
        )
      );

    this._characterEntity.body
      .getWorldTransform()
      .setRotation(three2BtQuat(verticalAlignmentRotation));
  }

  updatePhysic() {
    this._applyAttraction();
  }

  updateGraphic() {
    this.updateRingRotation();
  }

  updateRingRotation() {
    this._rotation += 0.0005;
    this._ring.rotation.set(this._rotation, this._rotation, 0);
  }

  toggleVisibility() {
    this.graphic.visible = this._sphere.visible;
  }
}
