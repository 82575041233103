import * as THREE from "three";
import canvasTxt from "canvas-txt";
import graphics from "../graphics.js";
import * as load from "audio-loader";

export function applyArtTexture(
  { project, creation, name, date, author, algorithm, workshop },
  material,
  artMesh
) {
  let canvas = document.createElement("canvas");
  canvas.width = 800;
  canvas.height = 600;
  canvas.style.backgroundColor = "black";

  let ctx = canvas.getContext("2d"); 

  addMetadata(ctx, name, author, date, algorithm, workshop, canvas.width / 2);

  // Comment here to turn off art fetching
  addArt(project, canvas, ctx, creation, material, artMesh);
}

function addRect(ctx, fillStyle, x, y, width, height) {
  ctx.fillStyle = fillStyle;
  ctx.fillRect(x, y, width, height);
}

function addFrame(ctx, height=430){
  addRect(ctx, "#FAEFC3", 30, 40, 430, height);
  addRect(ctx, "#F9D748", 50, 60, 390, height-40);
  addRect(ctx, "#d8bb6f", 60, 70, 370, height-60);
  addRect(ctx, "white", 71, 81, 350, height-80);
}

function addArt(project, canvas, ctx, creation, material, artMesh) {
  switch (project) {
    case Projects.MUSIC_AI:
      addMusic(canvas, ctx, creation, material, artMesh);
      break;
    case Projects.TEXT_AI:
      addFrame(ctx, 530);
      addText(canvas, ctx, creation, material);
      break;
    case Projects.VISUAL_AI:
      addFrame(ctx);
      addVisual(canvas, ctx, creation, material);
      break;
    default:
      break;
  }
}

function addMusic(canvas, ctx, creation, material, artMesh) {
  let img = new Image();
  img.onload = () => {
    ctx.drawImage(img, 20, 44);
    material.map = new THREE.CanvasTexture(canvas);
    material.needsUpdate = true;
  };

  img.src = "./assets/tests/on.png";

  const sound = new THREE.Audio(graphics.getAudioListener());
  load(creation).then((buffer) => {
    sound.setBuffer(buffer);
    sound.setLoop(true);
    sound.setVolume(0.5);
  });

  artMesh.add(sound);
}

function addText(canvas, ctx, creation, material) {
  let lines = creation.split("\\n");
  let lineheight = 15;
  ctx.font = "10px arial";
  ctx.fillStyle = "black"; 
  for (let i = lines.length - 1; i >= 0; i--) {
    if (lines[i].trim() === "") {
      lines.splice(i, 1);
    }
  }

  let y = 71;
  for (let i = 0; i < lines.length; i++) {
    let height = lines[i].length / 3 + lineheight;
    canvasTxt.drawText(
      ctx,
      lines[i],
      71,
      y,
      350,
      height
    );
    y+= height
  }

  material.map = new THREE.CanvasTexture(canvas);
  material.needsUpdate = true;
}

function addVisual(canvas, ctx, creation, material) {
  let img = new Image();
  img.onload = () => {
    ctx.drawImage(img, 71, 81, 350, 350);
    material.map = new THREE.CanvasTexture(canvas);
    material.needsUpdate = true;
  };

  img.crossOrigin = "anonymous";
  img.src = creation;
}

function addMetadata(ctx, name, author, date, algorithm, workshop, maxWidth) {
  ctx.fillStyle = "#ffffff";
  ctx.font = "32px arial";
  let height = adjustMetadata(ctx, `${name}`, 552, 400, 50);
  ctx.font = "20px arial";
  height = adjustMetadata(ctx, `${author}`, 552, height, 30);
  height = adjustMetadata(ctx, `${date}`, 552, height, 60);
  adjustMetadata(ctx, `${workshop}`, 552, height, 30);
}

function adjustMetadata(ctx, metaData, width, heigth, h) {
  let displayData = [metaData];
  if (ctx.measureText(metaData).width > width / 2) {
    let charSize = Math.round(
      ctx.measureText(metaData).width / metaData.length
    );
    let maxLineChar = Math.trunc(width / 2 / charSize) - 1;
    if (metaData[maxLineChar - 1] !== " ") {
      metaData =
        metaData.substring(0, maxLineChar - 1) +
        "-" +
        metaData.substring(maxLineChar - 1, metaData.length);
    }
    displayData = [
      metaData.substring(0, maxLineChar),
      metaData.substring(maxLineChar, metaData.length),
    ];
  }
  for (let i in displayData) {
    ctx.fillText(displayData[i], width, heigth);
    heigth += h;
  }
  return heigth;
}

const Projects = {
  MUSIC_AI: "MusicAI",
  TEXT_AI: "TextAI",
  VISUAL_AI: "VisualAI",
};
