import { Box, makeStyles, Paper, Tab, Tabs } from "@material-ui/core";
import { useState } from "react";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import SettingsIcon from "@material-ui/icons/Settings";
import InfoIcon from "@material-ui/icons/Info";
import VideogameAssetIcon from "@material-ui/icons/VideogameAsset";
import { TabContext } from "@material-ui/lab";
import { glPurple, menuBg } from "../../const.js";
import ArtPanel from "./components/artPanel.js";
import ControlsPanel from "./components/controlsPanel";
import SettingsPanel from "./components/settingsPanel";
import InfoPanel from "./components/infoPanel";
import { GlButton } from "../common.js";
import dataStore from "../../../datastore.js";
import {
  ARTWORK_LABEL,
  SETTINGS_LABEL,
  INFO_LABEL,
  CONTROLS_LABEL,
  CLOSE_BUTTON,
} from "./components/constants_text.js";
import {
  languageController,
  LANGUAGE_CHANGE,
} from "../../../language-controller";
import PubSub from "pubsub-js";

const useStyles = makeStyles({
  container: {
    position: "absolute",
    width: "60%",
    maxHeight: "calc(100% - 100px)",
    left: "calc(50% - 100px)",
    transform: "translateX(-50%)",
    margin: 50,
    backgroundColor: menuBg,
  },

  tabs: {
    flexGrow: 1,
    maxWidth: "100%",
    backgroundColor: menuBg,
  },

  textColorSecondary: {
    color: "lightgray",
    "&$selected": {
      color: glPurple,
    },
  },
  selected: {},

  tabIndicator: {
    backgroundColor: glPurple,
  },

  panel: {
    color: "white",
  },
});

function Menu({ onClose }) {
  const [language, setLanguage] = useState(languageController.language);
  PubSub.subscribe(LANGUAGE_CHANGE, () =>
    setLanguage(languageController.language)
  );

  const [value, setValue] = useState("3");
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue.toString());
  };

  async function loadRandom() {
    try {
      await dataStore.loadRandom();
      onClose();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  async function loadGallery(galleryParams) {
    try {
      await dataStore.loadGallery(galleryParams);
      onClose();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  let heigth = window.innerHeight / 400 < 2 ? window.innerHeight - 345 : 400;
  
  return (
    <>
      <TabContext value={value}>
        <Paper className={classes.container}>
          <Tabs
            className={classes.tabs}
            classes={{ indicator: classes.tabIndicator }}
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="secondary"
            aria-label="Gallery menu"
            width={"100%"}
          >
            <Tab
              icon={
                <img
                  src="ui/images/IA_Couleur_Galerie.png"
                  width={"64px"}
                  alt={"Gallery Logo"}
                ></img>
              }
              disabled
            />
            <Tab
              classes={{
                textColorSecondary: classes.textColorSecondary,
                selected: classes.selected,
              }}
              icon={<ImageSearchIcon />}
              label={ARTWORK_LABEL[language]}
              value="1"
            />
            <Tab
              classes={{
                textColorSecondary: classes.textColorSecondary,
                selected: classes.selected,
              }}
              icon={<VideogameAssetIcon />}
              label={CONTROLS_LABEL[language]}
              value="2"
            />
            <Tab
              classes={{
                textColorSecondary: classes.textColorSecondary,
                selected: classes.selected,
              }}
              icon={<SettingsIcon />}
              label={SETTINGS_LABEL[language]}
              value="3"
            />
            <Tab
              classes={{
                textColorSecondary: classes.textColorSecondary,
                selected: classes.selected,
              }}
              icon={<InfoIcon />}
              label={INFO_LABEL[language]}
              value="4"
            />
          </Tabs>
          <Box overflow="auto" marginY={6} height={heigth + "px"}>
            <ArtPanel
              loadGallery={loadGallery}
              loadRandom={loadRandom}
              className={classes.panel}
              value="1"
            />
            <ControlsPanel className={classes.panel} value="2" />
            <SettingsPanel className={classes.panel} value="3" />
            <InfoPanel className={classes.panel} value="4" />
          </Box>
          <Box padding={2} display="flex" justifyContent="flex-end">
            <GlButton
              onClick={onClose}
              variant="outlined"
              className="GLClose"
            >
              {CLOSE_BUTTON[language]}
            </GlButton>
          </Box>
        </Paper>
      </TabContext>
    </>
  );
}

export default Menu;
