const GL_TOKEN_HEADER = "X-Genielab-Mei-Rest-Token";

class Firebase {
  constructor() {
    this.firebaseUrl = process.env.REACT_APP_REST_API_URL;
    this.token = "";
    this.pingInterval = "";
    this.pingTimer = process.env.REACT_APP_PING_INTERVAL;
    this.offline = false;
  }

  async _fetchRequest(method, endpoint, body, onload, onerror) {
    if (this.token !== "" || endpoint === "/auth/anonymous") {
      let head = {
        method: method,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          [GL_TOKEN_HEADER]: this.token,
        }};
      if (typeof body == "object") {
        head["body"] = JSON.stringify(body);
      } 
      return await fetch(this.firebaseUrl + endpoint, head).then(async (response) => {
        let data = await response.json();
        return onload(data);
      }).catch((error) => {
        return onerror(error);
      });
    } else if (this.offline) {
      setTimeout(this._fetchRequest.bind(this, method, endpoint, body, onload, onerror), 5000);
    }
  }

  async connect() {
    const connectionInfos = {
      user: "user",
      project: "",
    };
    await this._fetchRequest("POST", "/auth/anonymous", connectionInfos, (response) => {
      this.token = response.token;
      this.ping();
      return true;
    }, () => {
      this.offline = this.offline < 1;
      return false;
    });
  }

  async getArt() {
    return await this._fetchRequest("GET", "/gallery/all", "", (response) => {
      return response;
    }, () => {
      return {};
    });
  }

  async getFilteredArts(workshop, author, date) {
    const response = await fetch(
      `${this.firebaseUrl}/gallery/author/${author}/date/${date}/workshop/${workshop}`,
      {
        headers: {
          [GL_TOKEN_HEADER]: this.token,
        },
      }
    );
    try {
      const artsData = await response.json();
      return artsData;
    } catch (e) {
      return {};
    }
  }

  async getRandomArts() {
    const response = await fetch(`${this.firebaseUrl}/gallery/random`, {
      headers: {
        [GL_TOKEN_HEADER]: this.token,
      },
    });
    try {
      const artsData = await response.json();
      return artsData;
    } catch (e) {
      return {};
    }
  }

  ping() {
    fetch(`${this.firebaseUrl}/auth/ping`, {
      method: "POST",
      headers: {
        [GL_TOKEN_HEADER]: this.token,
      },
    });
  }
}

let firebase = new Firebase();
export { firebase as default };
