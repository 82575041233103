import datastore from "./datastore.js";
import ammo from "./ammo.js";

export const BT_STATES = {
  DISABLE_DEACTIVATION: 4,
};

export const BT_FLAGS = {
  CF_STATIC_OBJECT: 1,
  CF_KINEMATIC_OBJECT: 2,
};

export const gravity = -9.8;

class Physics {
  constructor() {
    this._world = null;
  }

  async init() {
    let collisionConfiguration = new ammo.btDefaultCollisionConfiguration(),
      dispatcher = new ammo.btCollisionDispatcher(collisionConfiguration),
      overlappingPairCache = new ammo.btDbvtBroadphase(),
      solver = new ammo.btSequentialImpulseConstraintSolver();

    this._world = new ammo.btDiscreteDynamicsWorld(
      dispatcher,
      overlappingPairCache,
      solver,
      collisionConfiguration
    );
    this._world.setGravity(new ammo.btVector3(0, 0, 0));
  }

  // allows to add entities to the physical world
  addToWorld(entity) {
    this._world.addRigidBody(entity.body);
  }

  removeFromWorld(entity) {
    this._world.removeRigidBody(entity.body);
  }

  updatePhysics(deltaTime) {
    datastore.updateEntitiesPhysics();
    this._world.stepSimulation(deltaTime, 10);
    datastore.updateEntitiesGraphics(); // todo: this is odd, graphics update should be probably better elsewhere
  }
}
const physics = new Physics();

export { physics as default };
