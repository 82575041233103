import { Box, Container, Typography } from "@material-ui/core";
import { TabPanel } from "@material-ui/lab";
import {
  VISITING_SECTION_TITLE,
  VISITING_SECTION_CONTENT,
  VISITING_SECTION_LOGO,
} from "./constants_text.js";
import {
  languageController,
  LANGUAGE_CHANGE,
} from "../../../../language-controller";
import { useState } from "react";
import PubSub from "pubsub-js";

function ImageControls(props) {
  return (
    <Box display="flex" marginTop="50px">
      <Box textAlign="center">
        <img width="250px" src={props.src} alt={props.name} />
      </Box>
      <Box margin="auto 0" marginLeft="20px">
        {props.description}
      </Box>
    </Box>
  );
}

function InfoPanel({ ...props }) {
  const [language, setLanguage] = useState(languageController.language);
  PubSub.subscribe(LANGUAGE_CHANGE, () =>
    setLanguage(languageController.language)
  );
  return (
    <TabPanel {...props}>
      <Box py={2}>
        <Typography>{VISITING_SECTION_TITLE[language]}</Typography>
      </Box>
      <Container>
        <Typography element="body1" gutterBottom>
          {VISITING_SECTION_CONTENT[language]}
        </Typography>
        <Box
          justifyContent="space-evenly"
          minHeight="100px"
          display="flex"
          flexDirection="column"
        >
          {[
            {
              name: "logo",
              src: "ui/images/GenielabLogo-BVert_Copyright.png",
              title: "génielab logo",
              description: (
                <Typography component={"span"}>
                  {VISITING_SECTION_LOGO[language]}
                </Typography>
              ),
            },
          ].map((img) => (
            <ImageControls
              key={img.name}
              src={img.src}
              name={img.title}
              description={img.description}
            />
          ))}
        </Box>
      </Container>
    </TabPanel>
  );
}

export default InfoPanel;
