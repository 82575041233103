import styled from "styled-components";
import { Container, Link, Typography } from "@material-ui/core";

const StyledContainer = styled(Container)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 960px;
`;

function WebglMissingError() {
  return (
    <StyledContainer>
      <Typography align="center">
        WebGL is required to access the portal. It is either disabled or not
        supported by your web browser.
        <br />
        Follow this <Link href="https://get.webgl.org/">link</Link> to know
        more.
      </Typography>
    </StyledContainer>
  );
}

export default WebglMissingError;
