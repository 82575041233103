import Entity from "../entity.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { setupModel } from "../setupModels.js";

export class Props extends Entity {
  constructor(name, initProps, infos) {
    super(name, initProps);

    // const {
    //   dimensions: { width, height },
    // } = initProps;

    // if (_.isUndefined(width) || _.isUndefined(height)) {
    //   this._throwMissingInitialPropsError([
    //     "dimensions.width",
    //     "dimensions.height",
    //   ]);
    // }

    // if (_.isUndefined(infos)) {
    //   throw Error(`Missing infos for art piece ${name}`);
    // }

    // this._infos = infos;
    // this._btCollisionFlag = BT_FLAGS.CF_STATIC_OBJECT;
    // this._shape = new ammo.btBoxShape(
    //   new ammo.btVector3(width / 2, height / 2, 0.1)
    // );
  }

  //   _initGraphic() {
  //     const {
  //       dimensions: { width, height },
  //       position,
  //     } = this._initProps;

  //     const artMesh = new THREE.Object3D();
  //     const widePlane = new THREE.PlaneGeometry(width, height);
  //     const thinPlane = new THREE.PlaneGeometry(0.01, height);
  //     const basicWhiteMaterial = new THREE.MeshBasicMaterial({
  //       side: THREE.DoubleSide,
  //     });

  //     let texturedMaterial = new THREE.MeshBasicMaterial();
  //     const frontMesh = new THREE.Mesh(widePlane, texturedMaterial);
  //     applyArtTexture(this._infos, texturedMaterial, artMesh);
  //     artMesh.add(frontMesh);

  //     const backSide = new THREE.Mesh(widePlane, texturedMaterial);
  //     backSide.rotateY(Math.PI);
  //     backSide.position.set(0, 0, -0.01);
  //     artMesh.add(backSide);

  //     const rightSide = new THREE.Mesh(thinPlane, basicWhiteMaterial);
  //     rightSide
  //       .rotateY(Math.PI / 2)
  //       .translateZ(width / 2)
  //       .translateX(0.005);
  //     artMesh.add(rightSide);

  //     const leftSide = new THREE.Mesh(thinPlane, basicWhiteMaterial);
  //     leftSide
  //       .rotateY(Math.PI / 2)
  //       .translateZ(-width / 2)
  //       .translateX(0.005);
  //     artMesh.add(leftSide);

  //     const spotlightCone = new THREE.CylinderGeometry(
  //       0.5,
  //       width,
  //       height * 4,
  //       64,
  //       64,
  //       false
  //     );
  //     const basicLightMaterial = new THREE.MeshBasicMaterial({
  //       color: 0xffffcc,
  //       opacity: 0.1,
  //       transparent: true,
  //       side: THREE.DoubleSide,
  //     });
  //     const spotlight = new THREE.Mesh(spotlightCone, basicLightMaterial);
  //     artMesh.add(spotlight);

  //     artMesh.position.set(position.x, position.y, position.z);
  //     this.graphic = artMesh;
  //   }

  async init() {
    super.init();
    //const { position } = this._initProps;

    // load props for the world
    const loader = new GLTFLoader();

    const [ringData] = await Promise.all([
      loader.loadAsync("/assets/models/ring4.glb"),
      //loader.loadAsync('/assets/models/Flamingo.glb'),
      //loader.loadAsync('/assets/models/Stork.glb'),
    ]);

    console.log(ringData);

    const ring = setupModel(ringData);
    ring.scale.x = 20;
    ring.scale.y = 20;
    ring.scale.z = 20;

    // // align with sphere origin
    // let sphereCenterDirection = new THREE.Vector3(
    //   position.x,
    //   position.y,
    //   position.z
    // ).normalize();

    // // extract up axis of character transform basis
    // let up = new THREE.Vector3();
    // this.graphic.matrixWorld.extractBasis(
    //   new THREE.Vector3(),
    //   up,
    //   new THREE.Vector3()
    // );

    // let verticalAlignmentRotation = new Quaternion()
    //   .setFromUnitVectors(up, sphereCenterDirection)
    //   .multiply(bt2ThreeQuat(this.body.getWorldTransform().getRotation()));

    // this.body
    //   .getWorldTransform()
    //   .setRotation(three2BtQuat(verticalAlignmentRotation));
    // const orientation = this.body.getWorldTransform().getRotation();
    // this.graphic.quaternion.set(
    //   orientation.x(),
    //   orientation.y(),
    //   orientation.z(),
    //   orientation.w()
    // );
  }

  updateGraphic() {
    super.updateGraphic();
  }
}
