import { MathConst } from "./constants";
import random from "random";
import { Vector3 } from "three/build/three.module";

// http://corysimon.github.io/articles/uniformdistn-on-sphere/
export function getRandomSpherePosition(radius) {
  const teta = MathConst.PI_2 * random.float();
  const phi = Math.acos(1 - 2 * random.float());
  return {
    x: radius * Math.sin(phi) * Math.sin(teta),
    y: radius * Math.cos(phi),
    z: radius * Math.sin(phi) * Math.cos(teta),
  };
}

export function spheresIntersect(c1, c2, radius) {
  return getDistance(c1, c2) <= 2 * radius;
}

export function getDistance(c1, c2) {
  return Math.sqrt(
    Math.pow(c1.x - c2.x, 2) +
      Math.pow(c1.y - c2.y, 2) +
      Math.pow(c1.z - c2.z, 2)
  );
}

export function insideCone(cone, point) {
  const difference = new Vector3();
  difference.subVectors(point, cone.x);
  const coneDistance = difference.dot(cone.direction);
  const coneRadius = (coneDistance / cone.h) * cone.r;
  const orthogonal = new Vector3();
  orthogonal.subVectors(difference, cone.direction.multiplyScalar(cone.r));
  return orthogonal.length() < coneRadius;
}

export class Cone {
  x; // tip of the cone
  direction; // direction of the cone
  h; // height of the cone
  r; // base radius of the cone

  constructor(x, direction, h, r) {
    this.x = x;
    this.direction = direction;
    this.h = h;
    this.r = r;
  }
}

export function ArchimedeanSpiralPosition(radius, posSpiral, posMax) {
  let z = -(MathConst.PI_2 * (posSpiral - 1) - posMax) / posMax;
  console.log(z)
  return {
    z: radius * z,
    x: radius * Math.cos(Math.sqrt(posMax * Math.PI)*Math.asin(z))*Math.sqrt(1 - z*z),
    y: radius * Math.sin(Math.sqrt(posMax * Math.PI)*Math.asin(z))*Math.sqrt(1 - z*z),
  }
}
