import { Button, Slider, Switch, TextField, withStyles } from "@material-ui/core";
import { glPurple, glPurpleDimmed, glPurpleLight } from "../const.js";
import { KeyboardDatePicker } from "@material-ui/pickers";

export const GlButton = withStyles({
  root: {
    color: ({ variant }) => (variant === "outlined" ? glPurple : "white"),
    backgroundColor: ({ variant }) =>
      variant === "outlined" ? "white" : glPurple,
    border: ({ variant }) =>
      variant === "outlined" ? `solid 1px ${glPurpleDimmed}` : "none",
    "&:hover": {
      backgroundColor: ({ variant }) =>
        variant === "outlined" ? glPurpleLight : glPurpleDimmed,
    },
    flexGrow: ({ grow }) => (grow ? 1 : 0),
  },
})(Button);

export const GlTextfield = withStyles({
  root: {
    backgroundColor: "white",

    margin: "8px 0",
  },
})(TextField);

// todo: fix input styling (black -> white)
export const GlDatePicker = withStyles({
  root: {
    "& .MuiInput-root": {
      color: "white",
    },
    "& .MuiFormLabel-root": {
      color: "white",
    },
    "& .MuiSvgIcon-root": {
      fill: glPurple,
    },
    "& .MuiInput-underline:before": {
      borderBottom: `1px solid ${glPurple}`,
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: `2px solid ${glPurpleLight}`,
    },
  },
})(KeyboardDatePicker);

export const GLSwitch = withStyles({
  root: {
    "& .Mui-checked": {
      color: glPurple,
      /*'&:hover':{
        backgroundColor: rgb(115, 106, 228, 0.08),
        opacity: 0.08,
      },*/
      "& + .MuiSwitch-track": {
        backgroundColor: glPurple, 
      },
    }
  },
})(Switch);

export const GLSlider = withStyles({
  root: {
    color: glPurple,
  },
})(Slider);
