import { Box, Container, Typography } from "@material-ui/core";
import {
  CONTROLS_INSTRUCTIONS,
  FORWARD,
  LEFT,
  BACKWARD,
  RIGHT,
  SHIFT_INSTRUCTIONS,
  MOUSE_INSTRUCTIONS,
  ESCAPE_INSTRUCTIONS,
} from "./constants_text.js";
import {
  languageController,
  LANGUAGE_CHANGE,
} from "../../../../language-controller";
import ImageControls from "./ImageContols.js";
import { useState } from "react";
import PubSub from "pubsub-js";
import { TabPanel } from "@material-ui/lab";

function ControlsPanel({ ...props }) {
  const [language, setLanguage] = useState(languageController.language);
  PubSub.subscribe(LANGUAGE_CHANGE, () =>
    setLanguage(languageController.language)
  );

  return (
    <TabPanel {...props}>
      <Container>
        {/* <Box pb={2}>
          <Typography>{CONTROLS_TITLE[language]}</Typography>
        </Box> */}
        <Box
          justifyContent="space-evenly"
          minHeight="330px"
          display="flex"
          flexDirection="column"
        >
          {[
            {
              name: "keyboards",
              src: "/ui/images/keyboard-key.png",
              title: "keyboard control",
              width: "130px",
              description: (
                <Typography component={"span"}>
                  {CONTROLS_INSTRUCTIONS[language]}
                  <ul>
                    <li>W : {FORWARD[language]} </li>
                    <li>A : {LEFT[language]}</li>
                    <li>S : {BACKWARD[language]}</li>
                    <li>D : {RIGHT[language]}</li>
                  </ul>
                </Typography>
              ),
            },
            {
              name: "mouse",
              src: "/ui/images/computer-mouse.png",
              title: "mouse control",
              width: "60px",
              description: (
                <Typography>{MOUSE_INSTRUCTIONS[language]}</Typography>
              ),
            },
            {
              name: "shift",
              src: "/ui/images/SHIFTfr.png",
              title: "shift run",
              width: "80px",
              description: (
                <Typography>{SHIFT_INSTRUCTIONS[language]}</Typography>
              ),
            },
            {
              name: "escape",
              src: "/ui/images/escape-key.png",
              title: "escape control",
              width: "80px",
              description: (
                <Typography>{ESCAPE_INSTRUCTIONS[language]}</Typography>
              ),
            },
          ].map((img) => (
            <ImageControls
              key={img.name}
              src={img.src}
              name={img.title}
              width={img.width}
              description={img.description}
            />
          ))}
        </Box>
      </Container>
    </TabPanel>
  );
}

export default ControlsPanel;
