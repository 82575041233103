import * as THREE from "three/build/three.module.js";
import controllable from "./controllable.js";

class Graphics {
  constructor() {
    this._scene = new THREE.Scene();
    this._mainCamera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      100
    );
    this._mainCamera.layers.disable(1);
    this._topdownCamera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      100
    );
    this._topdownCamera.layers.enable(1);

    this._logo = new THREE.Sprite();

    this._renderer = new THREE.WebGLRenderer();

    this._arts = [];

    this._boundingBoxes = [];
  }

  init() {
    this._renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(this._renderer.domElement);

    // this._renderer.outputEncoding = THREE.sRGBEncoding;
    // this._renderer.gammaFactor = 1.0;

    this._topdownCamera.position.y = 5;
    this._topdownCamera.up.set(0, -1, 0);
    this._topdownCamera.lookAt(new THREE.Vector3(0, 0, 0));

    const light = new THREE.AmbientLight(0x4c4c4c, 3); // soft white light
    this._scene.add(light);

    const directionalLight = new THREE.DirectionalLight(0xfff6dd, 3);
    directionalLight.position.set(-400, -95, -140);
    this._scene.add(directionalLight);

    this._axesHelper = new THREE.AxesHelper(50);
    this._axesHelper.visible = false;
    this._scene.add(this._axesHelper);

    const loader = new THREE.CubeTextureLoader().setPath("assets/skybox/");
    this._scene.background = loader.load([
      "right.png",
      "left.png",
      "top.png",
      "bottom.png",
      "front.png",
      "back.png",
    ]);

    this._mainCameraHelper = new THREE.CameraHelper(this._mainCamera);
    this._mainCameraHelper.visible = false;
    this._scene.add(this._mainCameraHelper);

    window.addEventListener("resize", () => this._handleResize());
    window.addEventListener("click", () => this._startAudio());
  }

  _startAudio() {
    this._audioListener = new THREE.AudioListener();
    this._audioListener.sound = true;

    this._sound = new THREE.Audio(this._audioListener);

    const audioLoader = new THREE.AudioLoader();
    audioLoader.load(
      "./assets/audio/AIGallery-Background-Loop.mp3",
      (buffer) => {
        this._sound.setBuffer(buffer);
        this._sound.setLoop(true);
        this._sound.setVolume(0.2);
        this._sound.play();
      }
    );
    this._mainCamera.add(this._audioListener);
    window.removeEventListener("click", this._startAudio);
  }

  test(mat) {
    console.log(mat);
  }

  _handleResize() {
    this._renderer.setSize(window.innerWidth, window.innerHeight);
    this._mainCamera.ratio = window.innerWidth / window.innerHeight;
    this._mainCamera.updateProjectionMatrix();
    // this.updateHUDSprites();
  }

  // _updateHUDSprite() {

  //   const width = window.innerWidth / 2;
  //   const height = window.innerHeight / 2;

  //   this._logo.position.set( 0, 0, 1 );

  // }

  // _createHUDSprite( texture, scene ) {

  //   const material = new THREE.SpriteMaterial( { map: texture } );

  //   const width = material.map.image.width;
  //   const height = material.map.image.height;

  //   let logo = new THREE.Sprite( material );
  //   logo.center.set( 0.0, 1.0 );
  //   logo.scale.set( width, height, 1 );
  //   scene.add( logo );

  //   this._updateHUDSprites();

  // }

  toggleSound() {
    if (this._audioListener.sound) {
      this._audioListener.setMasterVolume(0.2);
    } else {
      this._audioListener.setMasterVolume(0);
    }
  }

  getMainCamera() {
    return this._mainCamera;
  }

  getTopdownCamera() {
    return this._topdownCamera;
  }

  getAudioListener() {
    return this._audioListener;
  }

  getAudioBackground() {
    return this._sound;
  }

  getRenderer() {
    return this._renderer;
  }

  getMainScene() {
    return this._scene;
  }

  addToScene(entity) {
    this._scene.add(entity.graphic);
  }

  addArt(entity) {
    this._scene.add(entity.graphic);
    this._boundingBoxes.push(entity.graphic);
    this._arts.push(entity);
  }

  removeFromScene(entity) {
    this._scene.remove(entity.graphic);
  }

  render() {
    this._renderer.setViewport(0, 0, window.innerWidth, window.innerHeight);
    this._renderer.setScissor(0, 0, window.innerWidth, window.innerHeight);
    this._renderer.setScissorTest(true);
    this._renderer.render(this._scene, this._mainCamera);

    // this is for the minimap in lower left corner
    this._renderer.setViewport(
      0,
      0,
      window.innerWidth / 4,
      window.innerHeight / 4
    );
    this._renderer.setScissor(
      0,
      0,
      window.innerWidth / 4,
      window.innerHeight / 4
    );
    this._renderer.setScissorTest(true);
    this._renderer.render(this._scene, this._topdownCamera);
  }
}

Object.assign(Graphics.prototype, controllable);

const graphics = new Graphics();
export { graphics as default };
