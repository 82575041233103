import PubSub from "pubsub-js";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { glPurple } from "../const.js";
import {
  ASSET_LOADED,
  ASSET_LOADING_PROGRESS,
  ASSET_LOADING_STARTED,
} from "../../state-manager.js";

const LOADING_BAR_WIDTH = 600;

const LoadingWrapper = styled.div`
  display: ${(props) => (props.visible ? "block" : "none")};
  //display: block;
  position: absolute;
  height: 10px;
  background-color: white;
  top: 10%;
  left: ${(document.body.clientWidth - LOADING_BAR_WIDTH) / 2}px;
  width: ${LOADING_BAR_WIDTH}px;
`;

const LoadingBar = styled(LoadingWrapper)`
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${glPurple};
  width: ${(props) => `${props.width}px`};
`;

const ProgressInfo = styled.div`
  position: relative;
  text-align: center;
  font-family: sans-serif;
  color: white;
  top: -25px;
`;

export default function Loading() {
  const [started, setStarted] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [currentBarWidth, setCurrentBarWidth] = useState(0);
  const [currentLoadingFile, setCurrentLoadingFile] = useState("");

  const [loadingStartHandler] = useState(() =>
    PubSub.subscribe(ASSET_LOADING_STARTED, () => setStarted(true))
  );
  const [loadingEndHandler] = useState(() =>
    PubSub.subscribe(ASSET_LOADED, () => setCompleted(true))
  );
  const [loadingProgressHandler] = useState(() =>
    PubSub.subscribe(ASSET_LOADING_PROGRESS, (e, data) => updateBar(data))
  );

  function updateBar({ url, itemsLoaded, itemsTotal }) {
    let width = (itemsLoaded * LOADING_BAR_WIDTH) / itemsTotal;
    setCurrentBarWidth(width);
    setCurrentLoadingFile(url);
  }

  useEffect(() => {
    return () => {
      PubSub.unsubscribe(loadingStartHandler);
      PubSub.unsubscribe(loadingEndHandler);
      PubSub.unsubscribe(loadingProgressHandler);
    };
  }, [loadingStartHandler, loadingEndHandler, loadingProgressHandler]);

  return (
    <LoadingWrapper visible={!completed}>
      {currentLoadingFile && (
        <ProgressInfo>{`Loading ${
          currentLoadingFile.match(/([\w.-])+$/)[0]
        }`}</ProgressInfo>
      )}
      <LoadingBar started={started} width={currentBarWidth} />
    </LoadingWrapper>
  );
}
