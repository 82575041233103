import { version } from "../../app-info.js";
import styled from "styled-components";
import { glPurple } from "../const.js";

const StyledVersion = styled.div`
  color: ${glPurple};
  font: 1.2em sans-serif;
  position: absolute;
  cursor: none;
`;

export default function Version() {
  return <StyledVersion>{version}</StyledVersion>;
}
