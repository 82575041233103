import "./style/global.css";
import * as THREE from "three/build/three.module.js";
import PubSub from "pubsub-js";
import { loadAmmo } from "./ammo.js";
import dataStore from "./datastore.js";
// import debugview from "./debug-view.js";

import React from "react";
import ReactDOM from "react-dom";
import App from "./ui/app.js";
import { CssBaseline } from "@material-ui/core";
import { APP_PAUSED, APP_RESUMED, APP_STARTED } from "./state-manager.js";

// webgl support detection
const ctx = document.createElement("canvas").getContext("webgl");

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline>
      <App webglSupported={ctx !== null} />
    </CssBaseline>
  </React.StrictMode>,
  document.getElementById("root")
);

let updateHandler;

if (ctx !== null) {
  (async () => {
    await loadAmmo();

    // we need to have AmmoJs loaded before importing all these modules
    const [
      { default: stateManager },
      { default: inputController },
      { default: physicsEngine },
      { default: graphicsEngine },
      // { default: devtools },
    ] = await Promise.all([
      import("./state-manager.js"),
      import("./input-controller.js"),
      import("./physics.js"),
      import("./graphics.js"),
      // import("./devtools.js"),
    ]);

    const startRenderLoop = () => {
      graphicsEngine._renderer.domElement.style.cursor = "none";
      update();
      // debugview.animate();
    };

    PubSub.subscribe(APP_STARTED, () => {
      startRenderLoop();
      inputController.init();
    });

    PubSub.subscribe(APP_PAUSED, () => {
      cancelAnimationFrame(updateHandler);
      graphicsEngine._renderer.domElement.style.cursor = "default";
    });

    PubSub.subscribe(APP_RESUMED, startRenderLoop);

    let clock = new THREE.Clock();

    // !! order matters here
    stateManager.init();
    graphicsEngine.init();
    physicsEngine.init();
    await dataStore.init();
    // debugview.init();
    // devtools.init();

    function update() {
      let deltaTime = clock.getDelta();

      physicsEngine.updatePhysics(deltaTime);
      graphicsEngine.render();
      if (updateHandler !== null) updateHandler = requestAnimationFrame(update);
    }
  })();
}
