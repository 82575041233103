import {
  Box,
  Menu,
  MenuItem,
  Grid,
  Container,
  Typography,
} from "@material-ui/core";
import { TabPanel } from "@material-ui/lab";
import {
  LANGUAGE_INSTRUCTIONS,
  AUDIO_INSTRUCTIONS,
  LANGUAGE_TITLE,
  AUDIO_TITLE,
  AUDIO_VOLUME_MAIN,
  AUDIO_VOLUME_BACKGROUND,
  ACCESSIBILITY_TITLE,
  ACCESSIBILITY_COMMANDE
} from "./constants_text.js";
import {
  languageController,
  LanguageOptions,
  publishLanguageChange,
  LANGUAGE_CHANGE,
} from "../../../../language-controller";
import ImageControls from "./ImageContols.js";
import { useState } from "react";
import PubSub from "pubsub-js";
import LanguageIcon from "@material-ui/icons/Language";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeUp from "@material-ui/icons/VolumeUp";
import graphics from "../../../../graphics.js";
import { GlButton, GLSlider, GLSwitch } from "../../common.js";
import inputController from "../../../../input-controller.js";

function SettingsPanel({ ...props }) {
  const [language, setLanguage] = useState(languageController.language);

  //language selection
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const languageOptions = ["Français", "English"];

  //volume settings
  const [valueMain, setValueMain] = useState(0.8);
  const [valueBackground, setValueBackground] = useState(0.5);
  const [valueDisplay, setvalueDisplay] = useState(inputController.getDisplayStatus());

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    if (index === 0) {
      console.log(index);
      languageController.language = LanguageOptions.FR;
      publishLanguageChange();
    } else if (index === 1) {
      languageController.language = LanguageOptions.EN;
      publishLanguageChange();
    }
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const mainVolumeChange = (event, newValue) => {
    setValueMain(newValue);
    graphics.getAudioListener().setMasterVolume(newValue);
  };

  const backgroundVolumeChange = (event, newValue) => {
    setValueBackground(newValue);
    graphics.getAudioBackground().setVolume(newValue);
  };

  const muteMainVolume = () => {
    setValueMain(0);
    graphics.getAudioListener().setMasterVolume(0);
  };

  const muteBackgroundVolume = () => {
    setValueBackground(0);
    graphics.getAudioBackground().setVolume(0);
  };

  const maxMainVolume = () => {
    setValueMain(1);
    graphics.getAudioListener().setMasterVolume(1);
  };

  const maxBackgroundVolume = () => {
    setValueBackground(1);
    graphics.getAudioBackground().setVolume(1);
  };

  const changeDisplay = (event, newValue) => {
    inputController.toggleDisplayStatus();
    setvalueDisplay(newValue);
  }

  PubSub.subscribe(LANGUAGE_CHANGE, () =>
    setLanguage(languageController.language)
  );

  return (
    <TabPanel {...props}>
      <Container>
        <Box pb={2}>
          <Typography element="body1" gutterBottom>
            {LANGUAGE_TITLE[language]}
          </Typography>
        </Box>
        <Box
          justifyContent="space-evenly"
          maxHeight="330px"
          display="flex"
          flexDirection="column"
          marginBottom="16px"
        >
          {[
            {
              name: "language",
              description: (
                <Typography component={"span"} element="body1" gutterBottom>
                  {LANGUAGE_INSTRUCTIONS[language]}
                  <Typography style={{ marginTop: 20 }}>
                    <GlButton
                      onClick={handleClick}
                      startIcon={<LanguageIcon></LanguageIcon>}
                    >
                      {languageOptions[selectedIndex]}
                    </GlButton>
                  </Typography>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {languageOptions.map((option, index) => (
                      <MenuItem
                        key={option}
                        // disabled={index === 0}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </Typography>
              ),
            },
          ].map((img) => (
            <ImageControls
              key={img.name}
              src={img.src}
              name={img.title}
              description={img.description}
            />
          ))}
        </Box>
        <Typography>{"\n"}</Typography>
        <Box pb={2}>
          <Typography>{AUDIO_TITLE[language]}</Typography>
        </Box>
        <Box
          justifyContent="space-evenly"
          maxHeight="330px"
          display="flex"
          flexDirection="column"
          marginBottom="16px"
        >
          {[
            {
              name: "audio",
              // src: "/ui/images/computer-mouse.png",
              // title: "mouse control",
              description: (
                <Box>
                  {AUDIO_INSTRUCTIONS[language]}
                  <Box>
                    {AUDIO_VOLUME_MAIN[language]}
                    <Grid container spacing={2}>
                      <Grid item>
                        <VolumeDown onClick={muteMainVolume} />
                      </Grid>
                      <Grid item xs>
                        <GLSlider
                          style={{ width: 300 }}
                          id="mainVolSlider"
                          name="mainVolSlider"
                          value={valueMain}
                          onChange={mainVolumeChange}
                          step={0.01}
                          min={0}
                          max={1}
                          aria-labelledby="continuous-slider"
                        />
                      </Grid>
                      <Grid item>
                        <VolumeUp onClick={maxMainVolume} />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    {AUDIO_VOLUME_BACKGROUND[language]}
                    <Grid container spacing={2}>
                      <Grid item>
                        <VolumeDown onClick={muteBackgroundVolume} />
                      </Grid>
                      <Grid item xs>
                        <GLSlider
                          style={{ width: 300 }}
                          value={valueBackground}
                          onChange={backgroundVolumeChange}
                          step={0.01}
                          min={0}
                          max={1}
                          aria-labelledby="continuous-slider"
                        />
                      </Grid>
                      <Grid item>
                        <VolumeUp onClick={maxBackgroundVolume} />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ),
            },
          ].map((img) => (
            <ImageControls
              key={img.name}
              src={img.src}
              name={img.title}
              description={img.description}
            />
          ))}
        </Box>
        <Box pb={2}>
          <Typography element="body1" gutterBottom>
            {ACCESSIBILITY_TITLE[language]}
          </Typography>
        </Box>
        <Box
          justifyContent="space-evenly"
          maxHeight="330px"
          display="flex"
          flexDirection="column"
          marginBottom="16px"
        >
          {[
            {
              name:"accessibility",
              description: (
                <Box>
                  <Typography component={"span"} element="body1" gutterBottom></Typography>
                  {ACCESSIBILITY_COMMANDE[language]}
                  <Typography style={{ marginTop: 20 }}>
                    <GLSwitch
                      onChange={changeDisplay}
                      defaultChecked={valueDisplay}
                    ></GLSwitch>
                  </Typography>
                </Box>
              ),
            },
          ].map((img) => (
            <ImageControls
              key={img.name}
              name={img.title}
              description={img.description}
            />
          ))}
        </Box>
      </Container>
    </TabPanel>
  );
}

export default SettingsPanel;
