import Version from "./components/version.js";
import Loading from "./components/loading.js";
import WebglMissingError from "./components/webgl-missing-error.js";
import Menu from "./components/menu";
import { useEffect, useState } from "react";
import PubSub from "pubsub-js";
import dataStore from "../datastore.js";
import { LoadingScreen } from "./components/menu/components/loading.js";
import inputController from "../input-controller.js";
import graphics from "../graphics.js";

// EVENTS
export const MENU_CLOSED = "ui.menu_closed";
export const MENU_OPENED = "ui.menu_opened";

function App({ webglSupported }) {
  const [showMenu, setShowMenu] = useState(true);

  useEffect(() => {
    const handler = (e) => {
      if ((e.key === "Escape" || e.key === "Esc") && e.keyCode !== 0) {
        if (showMenu) {
          handleCloseMenu();
        } else {
          PubSub.publish(MENU_OPENED);
          setShowMenu(true);
          inputController.toggleDisplay(true);
        }
      }
    };

    window.addEventListener("keydown", handler);
    return () => window.removeEventListener("keydown", handler);
  }, [showMenu]);

  async function handleCloseMenu() {
    let planet = window.location.pathname.substring(1);
    await dataStore.getArts({ workshop: planet});
    PubSub.publish(MENU_CLOSED);
    setShowMenu(false);
    inputController.toggleDisplay(false);
    console.log(graphics._sound);
  }

  return (
    <div>
      <LoadingScreen />
      <Version />
      {webglSupported && <Loading />}
      {!webglSupported && <WebglMissingError />}
      {showMenu && <Menu onClose={handleCloseMenu} />}
    </div>
  );
}

export default App;
