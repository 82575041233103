import PubSub from "pubsub-js";
import * as THREE from "three";
import { MENU_CLOSED, MENU_OPENED } from "./ui/app.js";

// EVENTS
const eventNamespace = "state_manager";

export const APP_STARTED = `${eventNamespace}.app_started`;
export const APP_PAUSED = `${eventNamespace}.app_paused`;
export const APP_RESUMED = `${eventNamespace}.app_resumed`;

export const ASSET_LOADED = `${eventNamespace}.asset_loaded`;
export const ASSET_LOADING_STARTED = `${eventNamespace}.asset_loading_started`;
export const ASSET_LOADING_PROGRESS = `${eventNamespace}.asset_loading_progress`; // payload { url, itemsLoaded, itemsTotal }

class StateManager {
  constructor() {
    this._started = false;
    this._paused = false;
  }

  init() {
    this._registerTHREEDefaultLoadingManagerEvents();

    PubSub.subscribe(MENU_CLOSED, () => {
      if (this._paused && this._started) {
        this._paused = false;
        PubSub.publish(APP_RESUMED);
      } else if (!this._started) {
        this._started = true;
        PubSub.publish(APP_STARTED);
      }
    });

    PubSub.subscribe(MENU_OPENED, () => {
      this._paused = true;
      PubSub.publish(APP_PAUSED);
    });
  }

  _registerTHREEDefaultLoadingManagerEvents() {
    THREE.DefaultLoadingManager.onStart = () =>
      PubSub.publish(ASSET_LOADING_STARTED);
    THREE.DefaultLoadingManager.onLoad = () => PubSub.publish(ASSET_LOADED);
    // THREE.DefaultLoadingManager.onError = (url) => {} // todo
    THREE.DefaultLoadingManager.onProgress = (url, itemsLoaded, itemsTotal) =>
      PubSub.publish(ASSET_LOADING_PROGRESS, {
        url,
        itemsLoaded,
        itemsTotal,
      });
  }
}

const manager = new StateManager();
export { manager as default };
