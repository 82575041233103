import _ from 'lodash';

export default {
  addDevControl(guilFolder, controllableName, propName, options) {
    const controllable = this[`_${controllableName}`];
    if (controllable && controllable[propName] !== undefined) {
      if (options) {
        // controller with range
        const { range } = options;
        if (range && !_.isUndefined(range.min) && !_.isUndefined(range.max)) {
          return guilFolder.add(controllable, propName, range.min, range.max);
        }
      }

      // simple controller
      return guilFolder.add(controllable, propName);
    }

    throw Error(`No ${controllableName} properties found on controllable`);
  },
};
