import PubSub from "pubsub-js";

export const LanguageOptions = {
  EN: "English",
  FR: "Français",
};

export let languageController = {
  language: LanguageOptions.FR,
};

export const LANGUAGE_CHANGE = "ui.language_change";

export function publishLanguageChange() {
  PubSub.publish(LANGUAGE_CHANGE);
}
