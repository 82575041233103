import Entity from "../entity.js";
import { BT_FLAGS } from "../physics.js";
import ammo from "../ammo.js";
import * as THREE from "three";
import { Quaternion } from "three";
import { bt2ThreeQuat, three2BtQuat } from "../util.js";
import _ from "lodash";
import { applyArtTexture } from "../lib/art-painter.js";

export class Art extends Entity {
  constructor(name, initProps, infos) {
    super(name, initProps);

    const {
      dimensions: { width, height },
    } = initProps;

    if (_.isUndefined(width) || _.isUndefined(height)) {
      this._throwMissingInitialPropsError([
        "dimensions.width",
        "dimensions.height",
      ]);
    }

    if (_.isUndefined(infos)) {
      throw Error(`Missing infos for art piece ${name}`);
    }

    this._infos = infos;
    this._btCollisionFlag = BT_FLAGS.CF_STATIC_OBJECT;
    this._shape = new ammo.btBoxShape(
      new ammo.btVector3(width / 2, height / 2, 0.1)
    );

    //this._spotlight = null;
    //this._spotlight2 = null;
    //this._rotation = 0.0; // to rotate the shape around the artworks
    this.artMesh = new THREE.Object3D();
  }

  _initGraphic() {
    const {
      dimensions: { width, height },
      position,
    } = this._initProps;

    // const this.artMesh = new THREE.Object3D();
    const widePlane = new THREE.PlaneGeometry(width, height);
    const thinPlane = new THREE.PlaneGeometry(0.01, height);
    const basicWhiteMaterial = new THREE.MeshBasicMaterial({
      side: THREE.DoubleSide,
    });

    let texturedMaterial = new THREE.MeshBasicMaterial();
    const frontMesh = new THREE.Mesh(widePlane, texturedMaterial);
    applyArtTexture(this._infos, texturedMaterial, this.artMesh);
    this.artMesh.add(frontMesh);

    const backSide = new THREE.Mesh(widePlane, texturedMaterial);
    backSide.rotateY(Math.PI);
    backSide.position.set(0, 0, -0.01);
    this.artMesh.add(backSide);

    const rightSide = new THREE.Mesh(thinPlane, basicWhiteMaterial);
    rightSide
      .rotateY(Math.PI / 2)
      .translateZ(width / 2)
      .translateX(0.005);
    this.artMesh.add(rightSide);

    const leftSide = new THREE.Mesh(thinPlane, basicWhiteMaterial);
    leftSide
      .rotateY(Math.PI / 2)
      .translateZ(-width / 2)
      .translateX(0.005);
    this.artMesh.add(leftSide);

    /*const spotlightCone = new THREE.TorusGeometry(
      0.5,
      width,
      height * 4,
      64,
      64
    ).scale(1, 1, 1.3);*/
    // const spotlightCone2 = new THREE.TorusGeometry(
    //   0.5,
    //   width,
    //   height * 4,
    //   64,
    //   64
    // ).scale(1.01, 1.01, 1.31).rotateY(Math.PI);
    /*const basicLightMaterial = new THREE.MeshBasicMaterial({
      color: 0xffffcc,
      opacity: 0.1,
      transparent: true,
      side: THREE.DoubleSide,
    });*/
    //this._spotlight = new THREE.Mesh(spotlightCone, basicLightMaterial);
    // this._spotlight2 = new THREE.Mesh(spotlightCone2, basicLightMaterial);
    //this.artMesh.add(this._spotlight);
    // this.artMesh.add(this._spotlight2);

    this.artMesh.position.set(position.x, position.y, position.z);
    this.graphic = this.artMesh;
  }

  init() {
    super.init();
    const { position } = this._initProps;

    // align with sphere origin
    let sphereCenterDirection = new THREE.Vector3(
      position.x,
      position.y,
      position.z
    ).normalize();

    // extract up axis of character transform basis
    let up = new THREE.Vector3();
    this.graphic.matrixWorld.extractBasis(
      new THREE.Vector3(),
      up,
      new THREE.Vector3()
    );

    let verticalAlignmentRotation = new Quaternion()
      .setFromUnitVectors(up, sphereCenterDirection)
      .multiply(bt2ThreeQuat(this.body.getWorldTransform().getRotation()));

    this.body
      .getWorldTransform()
      .setRotation(three2BtQuat(verticalAlignmentRotation));
    const orientation = this.body.getWorldTransform().getRotation();
    this.graphic.quaternion.set(
      orientation.x(),
      orientation.y(),
      orientation.z(),
      orientation.w()
    );
  }

  updateGraphic() {
    super.updateGraphic();
    // const shader = this._spotlight.material.userData.shader;
    this._rotation += 0.0005;
    //this._spotlight.rotation.set(0, 0, this._rotation);

    // TODO : Find a way to make other artworks not glitch when close to one, maybe scale down the other ones?
    //this.artMesh.scale.set(0.2,0.2,0.2);
  }

  playMusic() {
    for (let i = 0; i < this.artMesh.children.length; i++) {
      const child = this.artMesh.children[0];
      if (child.type === "Audio" && !child.isPlaying) {
        child.play();
      }
    }
  }

  stopMusic() {
    for (let i = 0; i < this.artMesh.children.length; i++) {
      const child = this.artMesh.children[0];
      if (child.type === "Audio" && child.isPlaying) {
        console.log(child);
        child.stop();
      }
    }
  }
}
