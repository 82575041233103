import Entity from "./entity.js";
import CharacterEntity from "./entities/character.js";
import physics from "./physics.js";
import PlanetEntity from "./entities/planet.js";
import graphics from "./graphics.js";
import { Art } from "./entities/art.js";
import { ArchimedeanSpiralPosition, getRandomSpherePosition, spheresIntersect } from "./lib/maths.js";
import firebase from "./firebase.js";
import { Props } from "./entities/props.js";
import { toggleLoading } from "./loading-controller.js";
import inputController from "./input-controller.js";

class Datastore {
  constructor() {
    this._entities = [];
    this._nbArts = 0;
  }

  SPHERE_RADIUS = 5.5;

  // careful, order of adding entities matters (because of order dependencies between physic updates)
  async init() {
    let character = this.addCharacter();
    await this.addPlanet(character);
    await firebase.connect();
  }

  _removesArts() {
    this._entities
      .filter((entity) => entity instanceof Art)
      .forEach((entity) => entity.dispose());

    this._entities = this._entities.filter(
      (entity) =>
        entity instanceof CharacterEntity || entity instanceof PlanetEntity
    );
  }

  async loadRandom() {
    return await this.getArts(null, true, true);
  }

  async loadGallery ({ author, workshop, date }) {
    return await this.getArts({ author, workshop, date }, true);
  }

  async getArts(params = null, reload = false, random = false) {
    toggleLoading();
    let arts = [];
    if (!firebase.offline && (reload || this._nbArts === 0)) {
      if (params !== null ) {
        let { author, workshop, date } = params;
        this._removesArts();
        author = author || "all";
        workshop = workshop || "all";
        date = date || "all";

        if (date !== "all") {
          date = new Intl.DateTimeFormat("fr-FR")
            .format(new Date(date))
            .replaceAll("/", "-");
        }
        arts = this.addArts(
          await firebase.getFilteredArts.bind(firebase, workshop, author, date)
        );
      } else if ( random ) {
          this._removesArts();
          arts = this.addArts(
            await firebase.getRandomArts.bind(firebase)
          );
      } else {
        arts = this.addArts( 
          await firebase.getArt.bind(firebase)
        );
      }
    }
    toggleLoading();
    if ( inputController.getDisplayStatus()){
      document.getElementById("controls").style.display = 'block';
      document.getElementById("looks").style.display = 'block';
    }
    return arts;
  }

  async addArts(fetcher) {
    const artsData = await fetcher();
    if (typeof artsData !== "string") {
      this._nbArts = artsData.length;
      artsData.forEach((artInfo, index) => {
        let position = ArchimedeanSpiralPosition(this.SPHERE_RADIUS, index+1, this._nbArts*3);
        let art = new Art(
          artInfo.name,
          {
            position: position,
            dimensions: {
              height: 0.75,
              width: 1,
            },
          },
          artInfo
        );

        art.init();
        this.addEntity(art);
        physics.addToWorld(art);
        graphics.addToScene(art);
        graphics.addArt(art);
      });
    }
  }

  changeSpherePosition(position) {
    let intersect = false;
    for (let i = 0; i < this._entities.length && !intersect; i++) {
      const entity = this._entities[i];
      if (!(entity instanceof PlanetEntity)) {
        intersect = spheresIntersect(entity.graphic.position, position, 10);
      }
    }
    return intersect;
  }

  initArtPosition() {
    let position;
    let trials = 10 * this._nbArts;
    do {
      position = getRandomSpherePosition(this.SPHERE_RADIUS);
      trials--;
    } while (this.changeSpherePosition(position) && trials > 0); // Prevents infinite loop

    return position;
  }

  async addPlanet(character) {
    let planet = new PlanetEntity(
      "planet",
      {
        position: { x: 0, y: 0, z: 0 },
        radius: 5,
      },
      character
    );
    await planet.init();
    this.addEntity(planet);
    physics.addToWorld(planet);
    graphics.addToScene(planet);
    return planet;
  }

  async addProps() {
    // maybe not async?
    let props = new Props();

    props.init();
    physics.addToWorld(props);
    graphics.addToScene(props);
    return { props };
  }

  addCharacter() {
    let character = new CharacterEntity("characterController", {
      mass: 1,
      position: { x: 0, y: 5.5, z: 0 },
      dimensions: {
        radius: 0.25,
        height: 1,
      },
    });
    character.init();
    this.addEntity(character);
    physics.addToWorld(character);
    graphics.addToScene(character);
    return character;
  }

  addEntity(entity) {
    if (!(entity instanceof Entity)) {
      throw new TypeError();
    }
    this._entities.push(entity);
  }

  updateEntitiesPhysics() {
    this._entities.forEach((entity) => entity.updatePhysic());
  }

  updateEntitiesGraphics() {
    this._entities.forEach((entity) => entity.updateGraphic());
  }

  findByName(name) {
    return this._entities.find((entity) => entity.name === name);
  }
}

let dataStore = new Datastore();
export { dataStore as default };
