import Entity from "../entity.js";
import inputController from "../input-controller.js";
import * as THREE from "three";
import ammo from "../ammo.js";
import { three2BtQuat, three2BtVec3 } from "../util.js";
import { BT_STATES } from "../physics.js";
import _ from "lodash";
import graphics from "../graphics.js";
import { MathUtils } from "three";
import { Vector3 } from "three/build/three.module";
const V_LOOK_ANGLE_MAX = MathUtils.degToRad(60);

export default class CharacterEntity extends Entity {
  constructor(name, initProps) {
    super(name, initProps);

    const {
      mass,
      position,
      dimensions: { radius, height },
    } = this._initProps;

    if (
      _.isUndefined(mass) ||
      _.isUndefined(position) ||
      _.isUndefined(radius) ||
      _.isUndefined(height)
    ) {
      this._throwMissingInitialPropsError();
    }

    this._shape = new ammo.btCylinderShape(
      new ammo.btVector3(radius * 0.5, height * 0.5, 0)
    );
    this._verticalLookRotation = 0;

    this._cylinder = null;
    this._geo = null;
    this._rotation = 0.0;
    this._direction = 1;
  }

  _initBody() {
    const { mass, position } = this._initProps;
    let transform = new ammo.btTransform();
    transform.setIdentity();
    transform.setOrigin(three2BtVec3(position));
    transform.setRotation(new ammo.btQuaternion(0, Math.PI, 0, 1));
    let motionState = new ammo.btDefaultMotionState(transform);
    let localInertia = new ammo.btVector3(0, 0, 0);
    this._shape.calculateLocalInertia(mass, localInertia);
    let rbInfo = new ammo.btRigidBodyConstructionInfo(
      mass,
      motionState,
      this._shape,
      localInertia
    );
    let body = new ammo.btRigidBody(rbInfo);
    body.setDamping(0, 1);
    body.setActivationState(BT_STATES.DISABLE_DEACTIVATION);
    this.body = body;
  }

  _initGraphic() {
    const {
      position,
      dimensions: { radius },
    } = this._initProps;
    this._geo = new THREE.TorusKnotGeometry(
      radius / 1.3,
      radius / 1.3,
      15,
      20,
      9,
      1
    ).rotateX(20);
    // const characterCylinder = new THREE.CylinderGeometry(
    //   radius,
    //   radius,
    //   height,
    //   32
    // );
    const characterMat = new THREE.MeshPhongMaterial({
      color: 0xF9D748,
      shininess: 50,
      flatShading: true,
      // roughness: 0,
      // metalness: 0.4,
    });
    this._cylinder = new THREE.Mesh(this._geo, characterMat);
    this._cylinder.position.set(position.x, position.y, position.z);
    this._cylinder.layers.disable(0);
    this._cylinder.layers.enable(1);

    // TODO FIX SHADOW SO IT WORKS
    // const shadowCylinder = new THREE.CylinderGeometry(radius, radius, 0.1, 32);
    // const shadowMat = new THREE.MeshLambertMaterial({ color: 0x828282 });
    // const shadow = new THREE.Mesh(shadowCylinder, shadowMat);

    this._localAxesHelper = new THREE.AxesHelper(1);
    this._localAxesHelper.visible = false;
    this._cylinder.add(this._localAxesHelper);
    this._cylinder.add(graphics.getMainCamera());
    this._cylinder.add(graphics.getTopdownCamera());
    // this._cylinder.add(this.shadow);
    graphics.getMainCamera().rotation.set(0, Math.PI, 0);
    this.graphic = this._cylinder;
  }

  _move() {
    let scalingFactor = 0.02;
    let moveX = inputController.getMoveX();
    let moveZ = inputController.getMoveZ();
    let deltaMouseX = inputController.getDeltaMouseX() * 0.01;
    let deltaMouseY = inputController.getDeltaMouseY() * 0.01;
    let run = inputController.getRunMove();

    if (moveX === 0 && moveZ === 0 && deltaMouseX === 0 && deltaMouseY === 0)
      return;

    let movement = new THREE.Vector3(moveX, 0, moveZ).normalize();
    movement.multiplyScalar(scalingFactor * (1 + run));
    let wantedWorldPos = this.graphic.localToWorld(movement);

    this.body
      .getWorldTransform()
      .setOrigin(
        new ammo.btVector3(wantedWorldPos.x, wantedWorldPos.y, wantedWorldPos.z)
      );

    let rotation = new THREE.Quaternion();
    rotation.setFromAxisAngle(new THREE.Vector3(0, 1, 0), deltaMouseX);
    let newOri = this.body
      .getWorldTransform()
      .getRotation()
      .op_mulq(three2BtQuat(rotation));
    this.body.getWorldTransform().setRotation(newOri);

    this._verticalLookRotation += deltaMouseY;
    this._verticalLookRotation = MathUtils.clamp(
      this._verticalLookRotation,
      -V_LOOK_ANGLE_MAX,
      V_LOOK_ANGLE_MAX
    );
    graphics
      .getMainCamera()
      .rotation.set(-this._verticalLookRotation, Math.PI, 0);
  }

  _correctVisualPollution() {
    let moveX = inputController.getMoveX();
    let moveZ = inputController.getMoveZ();

    if (moveX !== 0 || moveZ !== 0) {
      let direction = new Vector3();
      graphics._mainCamera.getWorldDirection(direction);

      let inside_index = null;
      let max_distance = 1000;
      for (let i = 0; i < graphics._boundingBoxes.length; i++) {
        const box = graphics._boundingBoxes[i];
        if (
          box.containsPoint(this._cylinder.position) &&
          box.distanceToPoint(this._cylinder.position) < max_distance
        ) {
          max_distance = box.distanceToPoint(this._cylinder.position);
          inside_index = i;
        }
      }

      for (let i = 0; i < graphics._arts.length; i++) {
        const art = graphics._arts[i];
        art.graphic.visible = true;

        if (i !== inside_index) {
          if (inside_index !== null) {
            art.graphic.visible = false;
          }
          art.stopMusic();
        } else {
          art.playMusic();
        }
      }
    }
  }

  updatePhysic() {
    this._move();
    //this._correctVisualPollution();
    // TODO : Add movement to the character in minimap
    // if (this._rotation >= 0.2 && this._direction === 1) {
    //   this._direction = -1;
    // } else if (this._rotation <= 0 && this._direction === -1){
    //   this._direction = 1;
    // }
    // this._rotation += 0.005 * this._direction;
    // console.log(this._rotation);
    // this._cylinder.scale(1 + this._rotation, 1, 1);
    // // this._geo.rotation.set(0, this._rotation, this._rotation);
    // // this._geo.scale(1 + this._rotation, 1, 1);
  }
}
