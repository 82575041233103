import { TabPanel } from "@material-ui/lab";
import { Box, Container, Typography } from "@material-ui/core";
import { useState } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import SearchIcon from "@material-ui/icons/Search";
import DateFnsUtils from "@date-io/date-fns";
import { GlButton, GlDatePicker, GlTextfield } from "../../common.js";
import {
  RESEARCH_TITLE,
  WORKSHOP_NAME_LABEL,
  AUTHOR_LABEL,
  WORKSHOP_DATE_LABEL,
  SEARCHING_SECTION_TEXT,
  NEW_ARTWORK_TITLE,
  LUCKY_BUTTON,
} from "./constants_text";
import {
  languageController,
  LANGUAGE_CHANGE,
} from "../../../../language-controller";
import ImageControls from "./ImageContols.js";
import PubSub from "pubsub-js";

function ArtPanel({ loadGallery, loadRandom, ...props }) {
  const [language, setLanguage] = useState(languageController.language);
  PubSub.subscribe(LANGUAGE_CHANGE, () =>
    setLanguage(languageController.language)
  );

  const workshop = window.location.pathname.substring(1);

  const [values, setValues] = useState({
    workshop: workshop,
    author: "",
    date: null,
  });

  function handleChangeTextFields(valueName) {
    return ({ target: { value } }) => {
      setValues({
        ...values,
        [valueName]: value,
      });
    };
  }

  function handleChangeDate(date) {
    setValues({
      ...values,
      date,
    });
  }

  async function handleSubmit() {
    loadGallery(values);
  }

  async function handleLucky() {
    loadRandom();
  }

  return (
    <TabPanel {...props}>
      <Container>
        <Box pb={2}>
          <Typography element="body1" gutterBottom>
            {NEW_ARTWORK_TITLE[language]}
          </Typography>
        </Box>
        <Box
          justifyContent="space-evenly"
          maxHeight="330px"
          display="flex"
          flexDirection="column"
          marginBottom="16px"
        >
          {[
            {
              name: "find",
              description: (
                <Typography component={"span"} element="body1" gutterBottom>
                  <Box marginTop={2} display="flex" /*width="128px"*/>
                    <GlButton
                      onClick={handleLucky}
                      grow="true"
                      startIcon={<SearchIcon></SearchIcon>}
                    >
                      {LUCKY_BUTTON[language]}
                    </GlButton>
                  </Box>
                </Typography>
              ),
            },
          ].map((img) => (
            <ImageControls
              key={img.name}
              src={img.src}
              name={img.title}
              description={img.description}
            />
          ))}
        </Box>
        <Typography>{"\n"}</Typography>
        <Box paddingBottom={2}>
          <Typography>{RESEARCH_TITLE[language]}</Typography>
        </Box>
        <Box
          justifyContent="space-evenly"
          maxHeight="330px"
          display="flex"
          flexDirection="column"
          marginBottom="16px"
        >
          {[
            {
              name: "search",
              description: (
                <Box>
                  <Typography>{SEARCHING_SECTION_TEXT[language]}</Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <form>
                      <Box
                        display="flex"
                        flexDirection="column"
                        width="400px"
                        justifyContent="space-between"
                      >
                        <GlTextfield
                          onChange={handleChangeTextFields("workshop")}
                          size="small"
                          variant="filled"
                          label={WORKSHOP_NAME_LABEL[language]}
                          defaultValue={values.workshop}
                          disabled={workshop!==""}
                        />
                        <GlTextfield
                          onChange={handleChangeTextFields("author")}
                          size="small"
                          variant="filled"
                          label={AUTHOR_LABEL[language]}
                        />
                        <GlDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          label={WORKSHOP_DATE_LABEL[language]}
                          value={values.date}
                          onChange={handleChangeDate}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                        <Box marginTop={2} display="flex" width="128px">
                          <GlButton onClick={handleSubmit} grow="true">
                            Go!
                          </GlButton>
                        </Box>
                      </Box>
                    </form>
                  </MuiPickersUtilsProvider>
                </Box>
              ),
            },
          ].map((img) => (
            <ImageControls
              key={img.name}
              src={img.src}
              name={img.title}
              description={img.description}
            />
          ))}
        </Box>
      </Container>
    </TabPanel>
  );
}

export default ArtPanel;
