import { Box } from "@material-ui/core";

function ImageControls(props) {
  return (
    <Box display="flex">
      <Box width="150px" textAlign="center">
        <img src={props.src} alt={props.name} width={props.width} />
      </Box>
      <Box margin="auto 0">{props.description}</Box>
    </Box>
  );
}

export default ImageControls;
