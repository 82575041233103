import ammo from "./ammo.js";
import controllable from "./controllable.js";
import _ from "lodash";
import { three2BtVec3 } from "./util.js";
import { BT_FLAGS } from "./physics.js";
import graphics from "./graphics.js";
import physics from "./physics.js";

// Abstract
class Entity {
  constructor(name = "jane do", initProps = {}) {
    if (_.isUndefined(initProps.position)) {
      this._throwMissingInitialPropsError("position");
    }

    this.name = name;
    this.body = null;
    this.graphic = null;
    this._initProps = initProps;
  }

  _throwMissingInitialPropsError(...propsName) {
    throw Error(
      `Missing initial properties ${propsName.join(",")} for ${this.name}`
    );
  }

  async init() {
    this._initBody();
    await this._initGraphic();
  }

  _initBody() {
    const { position } = this._initProps;

    let transform = new ammo.btTransform();
    transform.setIdentity();
    transform.setOrigin(three2BtVec3(position));
    transform.setRotation(new ammo.btQuaternion(0, 0, 0, 1));
    let motionState = new ammo.btDefaultMotionState(transform);
    ammo.destroy(transform);

    let localInertia = new ammo.btVector3(0, 0, 0);
    this._shape.calculateLocalInertia(0, localInertia);

    let rbInfo = new ammo.btRigidBodyConstructionInfo(
      0,
      motionState,
      this._shape,
      localInertia
    );
    ammo.destroy(motionState);
    // ammo.destroy(localInertia); // todo: somehow, this is causing an erro with ammo (see console)

    let body = new ammo.btRigidBody(rbInfo);
    ammo.destroy(rbInfo);

    if (!_.isUndefined(this._btCollisionFlag)) {
      body.setCollisionFlags(this._btCollisionFlag);
    }

    this.body = body;
  }

  dispose() {
    if (!this.graphic) return;

    this.graphic.traverse((child) => {
      if (child.geometry) {
        child.geometry.dispose();
      }

      if (child.material) {
        if (Array.isArray(child.material)) {
          child.material.forEach((mat) => {
            if (mat.map) {
              mat.map.dispose();
            }
            child.material.dispose();
          });
        } else {
          if (child.material.map) {
            child.material.map.dispose();
          }
          child.material.dispose();
        }
      }
    });

    physics.removeFromWorld(this);
    ammo.destroy(this.body);
    graphics.removeFromScene(this);
  }

  _initGraphic() {
    throw Error("Missing implementation");
  }

  updatePhysic() {}

  updateGraphic() {
    let ms = this.body.getMotionState();
    if (ms && !(this.body.getCollisionFlags() === BT_FLAGS.CF_STATIC_OBJECT)) {
      let transform = new ammo.btTransform();
      ms.getWorldTransform(transform);
      const origin = transform.getOrigin();
      const rotation = transform.getRotation();
      this.graphic.position.set(origin.x(), origin.y(), origin.z());
      this.graphic.quaternion.set(
        rotation.x(),
        rotation.y(),
        rotation.z(),
        rotation.w()
      );
    }
  }
}

Object.assign(Entity.prototype, controllable);
export default Entity;
