import PubSub from "pubsub-js";
import { isMobile } from "react-device-detect";
import { APP_PAUSED, APP_RESUMED, APP_STARTED } from "./state-manager.js";

const defaultKeys = {
  87: "forward", // w
  83: "back", // s
  65: "left", // a
  68: "right", // d
  40: "down", // arrow commands
  39: "rightM", // arrow commands
  38: "up", // arrow commands
  37: "leftM", // arrow commands*/
  16: "run", // shift
};

class InputController {

  _displayControls = isMobile;

  constructor({ keyCodes = defaultKeys } = {}) {
    this._keyCodes = keyCodes;
    this._moveDirection = {
      forward: 0,
      back: 0,
      left: 0,
      right: 0,
      run: 0,
      down: 0,
      rightM: 0,
      up: 0,
      leftM: 0,
    };

    // for mouse look
    this._mouseX = this._latestMouseX = 0;
    this._mouseY = this._latestMouseY = 0;

    this.initPointLock();
    //for accessibility
    this._addListener("W");
    this._addListener("A");
    this._addListener("S");
    this._addListener("D");
    this._addListener("(");
    this._addListener("'");
    this._addListener("&");
    this._addListener("%");
  }

  init() {
    const handleKeyDown = this._handleKeyDown.bind(this);
    const handleKeyUp = this._handleKeyUp.bind(this);
    const handleMouseMove = this._handleMouseMove.bind(this);
    const handleMouseMoveOnce = this._initMouseMoveHandling.bind(
      this,
      handleMouseMove
    );
    const handleEscape = (e) => {
      console.log(e.key);
      window.dispatchEvent(new KeyboardEvent("keydown", { key: "Esc" }));
    }

    // when app is unpaused/pointerLock is on
    const listenToEvents = () => {
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("keyup", handleKeyUp);
      document.addEventListener("mousemove", handleMouseMoveOnce, {
        once: true,
      });
      document.getElementById("logo").addEventListener("click", handleEscape);
    };

    PubSub.subscribe(APP_STARTED, listenToEvents);

    PubSub.subscribe(APP_RESUMED, listenToEvents);

    // when app is paused/pointerLock is off
    PubSub.subscribe(APP_PAUSED, () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("mousemove", handleMouseMove);
      document.getElementById("logo").removeEventListener("click", handleEscape);
    });

    listenToEvents();
  }

  initPointLock () {
    // elements for pointerLock
    let requestedElement = document.getElementById("root");

    requestedElement.requestPointerLock =
    requestedElement.requestPointerLock ||
    requestedElement.mozRequestPointerLock ||
    requestedElement.webkitRequestPointerLock;

    document.exitPointerLock =
    document.exitPointerLock ||
    document.mozExitPointerLock ||
    document.webkitExitPointerLock;

    document.addEventListener(
    "pointerlockchange",
    (e) => {
      if (document.pointerLockElement == null) {
        window.dispatchEvent(new KeyboardEvent("keydown", { key: "Esc", keyCode: 27 }));
      }
    },
    false
    );
  }

  _addListener(key_name) {
    document.getElementById(`${key_name}Key`).addEventListener("mousedown", (event) => {
      event.target.style.opacity = 0.6;
      this._handleKeyDown({"keyCode": key_name.charCodeAt(0)});
    });
    document.getElementById(`${key_name}Key`).addEventListener("touchstart", (event) => {
      event.target.style.opacity = 0.6;
      this._handleKeyDown({"keyCode": key_name.charCodeAt(0)});
    });
    document.getElementById(`${key_name}Key`).addEventListener("mouseup", (event) => {
      event.target.style.opacity = 0.3;
      this._handleKeyUp({"keyCode": key_name.charCodeAt(0)});
    });
  }

  _initMouseMoveHandling(handleMouseMoveListener, event) {
    this._mouseX = this._latestMouseX = event.movementX;
    this._mouseY = this._latestMouseY = event.movementY;

    document.addEventListener("mousemove", handleMouseMoveListener);
  }

  _handleMouseMove(event) {
    this._latestMouseX = event.movementX;
    this._latestMouseY = event.movementY;
  }

  _handleKeyDown(event) {
    this._setMoveDirection(event.keyCode, 1);
  }

  _handleKeyUp(event) {
    this._setMoveDirection(event.keyCode, 0);
  }

  _setMoveDirection(keyCode, move) {
    this._moveDirection[this._keyCodes[keyCode]] = move;
  }

  getMoveX() {
    return this._moveDirection.left - this._moveDirection.right;
  }

  getRunMove() {
    return this._moveDirection.run;
  }

  getMoveZ() {
    return this._moveDirection.forward - this._moveDirection.back;
  }

  getDeltaMouseX() {
    if (this._mouseX === this._latestMouseX || this._displayControls) {
      return this._moveDirection.leftM - this._moveDirection.rightM;
    }
    let delta = -this._latestMouseX;
    this._mouseX = this._latestMouseX;
    return delta;
  }

  getDeltaMouseY() {
    if (this._mouseY === this._latestMouseY || this._displayControls) {
      return this._moveDirection.up - this._moveDirection.down;
    }
    let delta = -this._latestMouseY;
    this._mouseY = this._latestMouseY;
    return delta;
  }

  toggleDisplay(display) {
    if (display) {
      document.getElementById("controls").style.display = 'none';
      document.getElementById("looks").style.display = 'none';    
    }
    else {
      if (!this.getDisplayStatus()) {
        document.getElementById("root").requestPointerLock();
      }
    }
  }

  toggleDisplayStatus() {
    this._displayControls = !this._displayControls;
  }

  getDisplayStatus() {
    return this._displayControls;
  }

}

let controller = new InputController();
export { controller as default };
