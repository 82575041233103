export const ARTWORK_SECTION_TITLE = {
  English: "ArtWorks",
  Français: "Oeuvres",
};

export const SEARCHING_SECTION_CONTENT = {
  start: {
    English: "Have a look in the ",
    Français: "Jetez un coup d'oeil à ",
  },
  link: {
    English: "search tab ",
    Français: "l'onglet de recherche ",
  },
  end: {
    English: "of this menu if you looking for something specific",
    Français: "du menu si vous recherchez quelqu chose de particulier",
  },
};

export const VISITING_SECTION_IMAGES = [
  {
    src: "/ui/images/VisualAI.png",
    title: "Visual AI",
  },
  {
    src: "/ui/images/TextAI.png",
    title: "Text AI",
  },
  {
    src: "/ui/images/AudioAI.png",
    title: "Audio AI",
  },
];

export const VISITING_SECTION_TITLE = {
  English: "ABOUT THE GALLERY",
  Français: "À PROPOS DE LA GALERIE",
};

export const VISITING_SECTION_CONTENT = {
  English:
    "Welcome to the GénieSphère art gallery! Works created by participants of the workshops offered by GénieLab on artificial intelligence can be admired here at your own pace.  Enjoy your visit!",
  Français:
    "Bienvenue dans la galerie d'art de la GénieSphère! Les œuvres créées par les participant·e·s des ateliers offerts par GénieLab sur l'intelligence artificielle peuvent être admirées ici à votre propre rythme.  Bonne visite!",
};

export const VISITING_SECTION_LOGO = {
  English: "  Built by GénieLab in Montréal, ©2021",
  Français: " Construit par GénieLab à Montréal, ©2021",
};

export const CONTROLS_TITLE = {
  English: "CONTROLS",
  Français: "CONTRÔLES",
};

export const CONTROLS_INSTRUCTIONS = {
  English: "Use the following keys to navigate into the gallery:",
  Français: "Utilisez les touches suivantes pour naviger dans la galerie:",
};

export const FORWARD = {
  English: "move forward",
  Français: "avancer",
};

export const LEFT = {
  English: "step left",
  Français: "aller à gauche",
};

export const BACKWARD = {
  English: "move backward",
  Français: "reculer",
};

export const RIGHT = {
  English: "step right",
  Français: "aller à droite",
};

export const SHIFT_INSTRUCTIONS = {
  English: "Hold down shift to run",
  Français: "Maintenir la touche majuscule enfoncée pour courir",
};

export const MOUSE_INSTRUCTIONS = {
  English: "Move your mouse to look around!",
  Français: "Utilisez votre souris pour regarder autour de vous!",
};

export const ESCAPE_INSTRUCTIONS = {
  English: "Press the escape key to toggle this menu anytime.",
  Français:
    "Pressez le bouton Echap pour faire apparaître ou disparaître le menu à tout moment",
};

export const ARTWORK_LABEL = {
  English: "ARTWORKS",
  Français: "OEUVRES",
};

export const RESEARCH_TITLE = {
  English: "SEARCH PARAMETERS",
  Français: "PARAMÈTRES DE RECHERCHE",
};

export const NEW_ARTWORK_TITLE = {
  English: "FIND OTHER ARTWORKS",
  Français: "TROUVER D'AUTRES OEUVRES",
};

export const SEARCHING_SECTION_TEXT = {
  English: "Enter information here to find specific artworks:",
  Français:
    "Entrez ici les informations pour retrouver des œuvres spécifiques:",
};

export const SETTINGS_LABEL = {
  English: "SETTINGS",
  Français: "PARAMÈTRES",
};

export const INFO_LABEL = {
  English: "INFO",
  Français: "INFO",
};

export const CONTROLS_LABEL = {
  English: "CONTROLS",
  Français: "CONTRÔLES",
};

export const LANGUAGE_TITLE = {
  English: "LOCALISATION",
  Français: "LOCALISATION",
};

export const LANGUAGE_INSTRUCTIONS = {
  English: "Pick the language of your choice:",
  Français: "Sélectionner la langue de votre choix:",
};

export const AUDIO_TITLE = {
  English: "AUDIO",
  Français: "AUDIO",
};

export const AUDIO_INSTRUCTIONS = {
  English: "Set the audio levels here:",
  Français: "Ajuster les niveaux sonores ici:",
};

export const AUDIO_VOLUME_MAIN = {
  English: "Main volume",
  Français: "Volume principal",
};

export const AUDIO_VOLUME_BACKGROUND = {
  English: "Background music volume",
  Français: "Volume de la musique de fond",
};

export const ACCESSIBILITY_TITLE = {
  English: "ACCESSIBILITY",
  Français: "ACCESSIBILITE",
}

export const ACCESSIBILITY_COMMANDE = {
  English: "Add controls on the screen:",
  Français: "Ajouter les contrôles sur l'écran :",
}

export const WORKSHOP_NAME_LABEL = {
  English: "Workshop name",
  Français: "Nom de l'atelier",
};

export const AUTHOR_LABEL = {
  English: "Artist's name",
  Français: "Nom de l'artiste",
};

export const WORKSHOP_DATE_LABEL = {
  English: "Workshop date",
  Français: "Date de l'atelier",
};

export const CLOSE_BUTTON = {
  English: "Close",
  Français: "Fermer",
};

export const LOADING_MESSAGE = {
  English: "Loading art pieces...",
  Français: "Chargement des oeuvres...",
};

export const LUCKY_BUTTON = {
  English: "Wish me luck",
  Français: "J'ai de la chance",
};
