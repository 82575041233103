import {
  LOADING_CHANGE,
  loadingController,
} from "../../../../loading-controller";
import {
  languageController,
  LANGUAGE_CHANGE,
} from "../../../../language-controller";
import * as loader from "../../../../entities/74927-clear-night-moon.json";
import Lottie from "react-lottie";
import PubSub from "pubsub-js";
import { useState } from "react";
import { Typography } from "@material-ui/core";
import { LOADING_MESSAGE } from "./constants_text.js";

export function LoadingScreen() {
  const [isLoading, setLoading] = useState(loadingController);
  PubSub.subscribe(LOADING_CHANGE, () => setLoading(loadingController));

  const [language, setLanguage] = useState(languageController.language);
  PubSub.subscribe(LANGUAGE_CHANGE, () =>
    setLanguage(languageController.language)
  );

  const style = {
    background: "black",
    color: "white",
    textAlign: "center",
    fontSize: "50px",
    height: "100%",
    width: "100%",
    position: "absolute",
    display: isLoading ? "initial" : "none",
  };

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loader.default,
    renderSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    clearCanvas: true,
    hideOnTransparent: true,
  };

  return (
    <div style={style}>
      {isLoading ? (
        <>
          <Lottie options={loadingOptions} height={700} width={1000}></Lottie>
          <Typography>{LOADING_MESSAGE[language]}</Typography>
        </>
      ) : null}
    </div>
  );
}
