import PubSub from "pubsub-js";

export let loadingController = false;

export const LOADING_CHANGE = "ui.loading_change";

export function toggleLoading() {
  loadingController = !loadingController;
  publishLoadingChange(LOADING_CHANGE);
}

export function publishLoadingChange() {
  PubSub.publish(LOADING_CHANGE);
}
